import store from "@/state/store";
export default [
    {
        path: "/logout",
        name: "logout",
        meta: {
          title: "Logout", authRequired: true,
          beforeResolve(routeTo, routeFrom, next) {
            localStorage.clear();
            sessionStorage.clear();
            next();
          },
        },
        component: () => import("../views/auth/logout/basic")
      },

      {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
          title: "Login",
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters["auth/loggedIn"]) {
              // Redirect to the home page instead
              next({ name: "default" });
            } else {
              // Continue to the login page
              next();
            }
          },
        },
      },
      {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
          title: "Forgot Password",
          beforeResolve(routeTo, routeFrom, next) {
            // If the user is already logged in
            if (store.getters["auth/loggedIn"]) {
              // Redirect to the home page instead
              next({ name: "default" });
            } else {
              // Continue to the login page
              next();
            }
          },
        },
      },
]