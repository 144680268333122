import orders from "./orders";
import settings from "./settings";
import content from "./content";
import product from "./product";
import auth from "./auth";
import marketing from "./marketing";
import Users from "./Users";
export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/dashboard/ecommerce/index.vue"),
  },
  ...auth,
  ...product,
  ...orders,
  ...marketing,
  ...content,
  ...Users,
  ...settings
];