export default [
    {
        path: "/contactMessages",
        name: "contact messages",
        meta: {title: "contact messages", authRequired: true},
        component: () => import("../views/contactMessages/index"),
    },
    {
        path: "/contactMessages/:id",
        name: "contact messages show",
        meta: {title: "contact messages show", authRequired: true},
        component: () => import("../views/contactMessages/show"),
    },
    {
        path: "/orderStatus",
        name: "order status",
        meta: {title: "order status", authRequired: true},
        component: () => import("../views/orderStatus/index"),
    },
    {
        path: "/orderStatus/add",
        name: "order status add",
        meta: {title: "order status add", authRequired: true},
        component: () => import("../views/orderStatus/form"),
    }
    ,
    {
        path: "/orderStatus/edit/:id",
        name: "order status edit",
        meta: {title: "order status edit", authRequired: true},
        component: () => import("../views/orderStatus/form"),
    },
    {
        path: "/order-returns",
        name: "order returns",
        meta: {title: "returns", authRequired: true},
        component: () => import("../views/orders/returns/index"),
    },
    {
        path: "/order-returns/add",
        name: "order returns add",
        meta: {title: "returns", authRequired: true},
        component: () => import("../views/orders/returns/form"),
    },
    {
        path: "/order-returns/:id",
        name: "order returns show",
        meta: {title: "order returns show", authRequired: true},
        component: () => import("../views/orders/returns/show"),
    },
    {
        path: "/order-returns/edit/:id",
        name: "order returns edit",
        meta: {title: "order returns edit", authRequired: true},
        component: () => import("../views/orders/returns/form"),
    },
    {
        path: "/orders",
        name: "orders",
        meta: {title: "orders", authRequired: true},
        component: () => import("../views/orders/index"),
    },
    {
        path: "/orders/:id",
        name: "orders show",
        meta: {title: "orders show", authRequired: true},
        component: () => import("../views/orders/show"),
    },
    {
        path: "/orders/add",
        name: "orders add",
        meta: {title: "orders add", authRequired: true},
        component: () => import("../views/orders/form"),
    },
    {
        path: "/orders/edit/:id",
        name: "orders edit",
        meta: {title: "orders edit", authRequired: true},
        component: () => import("../views/orders/form"),
    },
    {
        path: "/abandonedCarts",
        name: "Abandoned carts",
        meta: {title: "Abandoned carts", authRequired: true},
        component: () => import("../views/abandonedCarts/index"),
    },
    {
        path: "/abandonedCarts/:id",
        name: "Abandoned carts show",
        meta: {title: "Abandoned carts show", authRequired: true},
        component: () => import("../views/abandonedCarts/show"),
    },
]