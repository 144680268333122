import appConfig from "../../app.config";

export const linkGnirator = (path) => {    
    return appConfig.baseUrl + '/api/dashboard/' + path ;
}

export const mediaLinkGnirator = (path) => {    
    return appConfig.baseUrl+ '/storage/' + path ;
}

export default linkGnirator