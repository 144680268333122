<template>
 <router-view :key="$route.fullPath"></router-view>

</template>

<script>
import { getSetting } from '@/helpers/setting'
export default {
  name: 'App',
  methods: {getSetting},
  mounted() {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
    }
    link.href = getSetting('icon');
  }
}
</script>
