export default [
    {
        path: "/settings",
        name: "settings",
        meta: {title: "settings", authRequired: true},
        component: () => import("../views/settings/form"),
    },
    {
        path: "/languages",
        name: "languages",
        meta: {title: "languages", authRequired: true},
        component: () => import("../views/languages/index"),
    },
    {
        path: "/languages/edit/:id",
        name: "languages edit",
        meta: {title: "languages edit", authRequired: true},
        component: () => import("../views/languages/form"),
    },
    {
        path: "/currencies",
        name: "currencies",
        meta: {title: "currencies", authRequired: true},
        component: () => import("../views/currencies/index"),
    },
    {
        path: "/currencies/add",
        name: "currencies add",
        meta: {title: "currencies add", authRequired: true},
        component: () => import("../views/currencies/form"),
    }
    ,
    {
        path: "/currencies/edit/:id",
        name: "currencies edit",
        meta: {title: "currencies edit", authRequired: true},
        component: () => import("../views/currencies/form"),
    }

    ,
    {
        path: "/countries",
        name: "countries",
        meta: {title: "countries", authRequired: true},
        component: () => import("../views/countries/index"),
    },
    {
        path: "/countries/add",
        name: "countries add",
        meta: {title: "countries add", authRequired: true},
        component: () => import("../views/countries/form"),
    }
    ,
    {
        path: "/countries/edit/:id",
        name: "countries edit",
        meta: {title: "countries edit", authRequired: true},
        component: () => import("../views/countries/form"),
    }

    ,
    {
        path: "/cities",
        name: "cities",
        meta: {title: "cities", authRequired: true},
        component: () => import("../views/cities/index"),
    },
    {
        path: "/cities/add",
        name: "cities add",
        meta: {title: "cities add", authRequired: true},
        component: () => import("../views/cities/form"),
    }
    ,
    {
        path: "/cities/edit/:id",
        name: "cities edit",
        meta: {title: "cities edit", authRequired: true},
        component: () => import("../views/cities/form"),
    },

    {
        path: "/paymentGates",
        name: "paymentGates",
        meta: {title: "payment gates", authRequired: true},
        component: () => import("../views/paymentGates/index"),
    },
    {
        path: "/paymentGates/add",
        name: "paymentGates add",
        meta: {title: "payment gates add", authRequired: true},
        component: () => import("../views/paymentGates/form"),
    }
    ,
    {
        path: "/paymentGates/edit/:id",
        name: "paymentGates edit",
        meta: {title: "payment gates edit", authRequired: true},
        component: () => import("../views/paymentGates/form"),
    },

    {
        path: "/paymentMethods",
        name: "paymentMethods",
        meta: {title: "payment methods", authRequired: true},
        component: () => import("../views/paymentMethods/index"),
    },
    {
        path: "/paymentMethods/add",
        name: "paymentMethods add",
        meta: {title: "payment methods add", authRequired: true},
        component: () => import("../views/paymentMethods/form"),
    }
    ,
    {
        path: "/paymentMethods/edit/:id",
        name: "paymentMethods edit",
        meta: {title: "payment methods edit", authRequired: true},
        component: () => import("../views/paymentMethods/form"),
    },


    {
        path: "/shippings",
        name: "shippings",
        meta: {title: "shippings", authRequired: true},
        component: () => import("../views/shippings/index"),
    },
    {
        path: "/shippings/add",
        name: "shippings add",
        meta: {title: "shippings add", authRequired: true},
        component: () => import("../views/shippings/form"),
    }
    ,
    {
        path: "/shippings/edit/:id",
        name: "shippings edit",
        meta: {title: "shippings edit", authRequired: true},
        component: () => import("../views/shippings/form"),
    },

    {
        path: "/shippingFees",
        name: "shippingFees",
        meta: {title: "shipping fees", authRequired: true},
        component: () => import("../views/shippingFees/index"),
    },
    {
        path: "/shippingFees/add",
        name: "shippingFees add",
        meta: {title: "shipping fees add", authRequired: true},
        component: () => import("../views/shippingFees/form"),
    }
    ,
    {
        path: "/shippingFees/edit/:id",
        name: "shippingFees edit",
        meta: {title: "shipping fees edit", authRequired: true},
        component: () => import("../views/shippingFees/form"),
    },
]