import axios from 'axios'
import linkGenrator from '@/helpers/linkGenrator'
import i18n from '@/i18n'

const settingsName = 'settings'

export const getSetting = (key, translated) => {
    // get all settings from localstorage
    const settings = getOfflineSettings()
    // get setting value
    let setting = settings[key] ?? ''
    // translate value if translated true
    if (translated) setting = setting[i18n.global.locale] ?? ''
    return setting
}

export const getSettings = async () => {
    // get data from serve
    const response = await axios.get(linkGenrator(settingsName))
    // add data to localstorage
    return localStorage.setItem(settingsName, JSON.stringify(response.data.data));
}


export const getOfflineSettings = () => {
    // get data from localstorage if exist
    let sittings = 
        JSON.parse(localStorage.getItem(settingsName)) ?? 
        {
            "logo": "",
            "icon": "",
            "shipping_fee": 0,
            "tax": 0,
            "name": {
                "en": "",
                "ar": ""
            }
        };

    return sittings
}