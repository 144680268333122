export default [
    {
        path: "/users",
        name: "users",
        meta: { title: "users", authRequired: true },
        component: () => import("../views/users/index"),
    },
    {
        path: "/users/add",
        name: "users add",
        meta: { title: "users add", authRequired: true },
        component: () => import("../views/users/form"),
    },
    {
        path: "/users/edit/:id",
        name: "users edit",
        meta: { title: "users edit", authRequired: true },
        component: () => import("../views/users/form"),
    },
    {
        path: "/roles",
        name: "roles",
        meta: { title: "roles", authRequired: true },
        component: () => import("../views/roles/index"),
    },
    {
        path: "/roles/add",
        name: "roles add",
        meta: { title: "roles add", authRequired: true },
        component: () => import("../views/roles/form"),
    }
    ,
    {
        path: "/roles/edit/:id",
        name: "roles edit",
        meta: { title: "roles edit", authRequired: true },
        component: () => import("../views/roles/form"),
    },
    {
        path: "/customers",
        name: "customers",
        meta: { title: "customers", authRequired: true },
        component: () => import("../views/customers/index"),
    }
]