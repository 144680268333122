export default [
  {
    path: "/discountCodes",
    name: "discount codes",
    meta: { title: "discount codes", authRequired: true },
    component: () => import("../views/discountCodes/index"),
  },
  {
    path: "/discountCodes/add",
    name: "discount codes add",
    meta: { title: "discount codes add", authRequired: true },
    component: () => import("../views/discountCodes/form"),
  }
  ,
  {
    path: "/discountCodes/edit/:id",
    name: "discount codes edit",
    meta: { title: "discount codes edit", authRequired: true },
    component: () => import("../views/discountCodes/form"),
  },

  
  {
    path: "/partners",
    name: "partners",
    meta: { title: "partners", authRequired: true },
    component: () => import("../views/partners/index"),
  },
  {
    path: "/partners/:id",
    name: "partners show",
    meta: { title: "partners show", authRequired: true },
    component: () => import("../views/partners/show"),
  },
  {
    path: "/partners/add",
    name: "partners add",
    meta: { title: "partners add", authRequired: true },
    component: () => import("../views/partners/form"),
  },
  {
    path: "/partners/edit/:id",
    name: "partners edit",
    meta: { title: "partners edit", authRequired: true },
    component: () => import("../views/partners/form"),
  },
]